import React from "react";
import { Link } from "gatsby";
import logo from '../assets/logo.png';
import logotype from '../assets/logotype.png';
import moment from "moment";
import styled from "styled-components";
import {Github, Bitcoin, Keybase, Discord, Twitter} from 'styled-icons/fa-brands';
import { OutboundLink } from 'gatsby-plugin-google-analytics'


const Container = styled.div`
  margin: 0 auto;
  max-width: 740px;
  padding: 0.5rem;
`;

const Logo = styled.img`
  margin-bottom: 0;
`;

export const Row = styled.div`
  &::after {
    content: "";
    clear: both;
    display: table;
  }
`;

export const Column = styled.div`
  float: left;
  width: ${props => (props.span ? props.span / 12 * 100 : "8.33")}%;
`;

const LogoType = styled.img`
   margin: 0;
`;

const Copyright = styled.span`
  font-size: 75%;
`;

const FooterBlarg = styled.span`
  font-size: 75%;
  line-height: 1;
`;

const ListLink = props => (
  <li style={{ display: `inline-block`, marginRight: `1rem` }}>
    {(() => {
      if (props.href) {
        return <OutboundLink href={props.href} target="_blank" rel="noopener noreferrer">{props.children}</OutboundLink>;
      } else {
        return <Link to={props.to}>{props.children}</Link>;
      }
    })()}
  </li>
);

const Header = props => (
  <header>
    <Container>
      <Link to="/"><Logo src={logo} alt="Website logo" /></Link>
      <ul style={{ listStyle: `none`, float: `right`, marginTop: `1rem` }}>
        <ListLink href="https://streamlink.github.io/">Streamlink</ListLink>
      </ul>
    </Container>
  </header>
);

const Footer = props => (
    <footer style={{borderTop: "1px solid #e8e8e8", padding: "0.5rem 0", color: `grey`}}>
    <Container>
      <LogoType src={logotype} alt="beardypig" />
      <Row>
        <Column span={6}>
          <Row>
            <Copyright>Copyright © beardypig 2015 - {moment().format('YYYY')}</Copyright>
          </Row>
          <Row>
            <ul style={{ listStyle: `none`, float: `left`, marginLeft: `0rem` }}>
              <li style={{ display: `inline-block`, marginRight: `0.5rem` }}><OutboundLink href="https://www.github.com/beardypig" target="_blank" rel="noopener noreferrer"><Github size="1.25rem" style={{paddingBottom: "0.1rem"}}/></OutboundLink></li>
              <li style={{ display: `inline-block`, marginRight: `0.5rem` }}><a href="bitcoin:1562daKz2SSR1diU7utsi8b97a8XdcoWx1" target="_blank" rel="noopener noreferrer"><Bitcoin size="1.25rem" style={{paddingBottom: "0.1rem"}}/></a></li>
              <li style={{ display: `inline-block`, marginRight: `0.5rem` }}><OutboundLink href="https://keybase.io/beardypig" target="_blank" rel="noopener noreferrer"><Keybase size="1.25rem" style={{paddingBottom: "0.1rem"}}/></OutboundLink></li>
              <li style={{ display: `inline-block`, marginRight: `0.5rem` }}><OutboundLink href="https://discord.gg/NDhFh7e" target="_blank" rel="noopener noreferrer"><Discord size="1.25rem" style={{paddingBottom: "0.1rem"}}/></OutboundLink></li>
              <li style={{ display: `inline-block`, marginRight: `0.5rem` }}><OutboundLink href="https://www.twitter.com/beardypig" target="_blank" rel="noopener noreferrer"><Twitter size="1.25rem" style={{paddingBottom: "0.1rem"}}/></OutboundLink></li>
            </ul>
          </Row>
        </Column>
        <Column span={6}>
          <FooterBlarg>A blog mostly about stuff that I enjoy making or doing, expect posts about retro gaming, emulation, programming and electronics!</FooterBlarg>
        </Column>
      </Row>
    </Container>
  </footer>
);

export const Layout = ({ children }) => (
  <div>
  <Header />
  <Container>
  {children}
  </Container>
  <Footer />
  </div>
);
